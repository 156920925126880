import React,{useEffect} from 'react'
import {Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ListPacking from './ListPacking';
import AddNewPacking from './AddNewPacking';
import EditPacking from './EditPacking';




export const Packing_Index = ()=> {


    return (
    <Routes>
        <Route path="/" element={<ListPacking/>} /> 
        <Route path="/add" element={<AddNewPacking/>} /> 
        <Route path="/edit" element={<EditPacking/>} /> 
    </Routes>)
}
