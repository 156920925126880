import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import {
  ReadConfiguration,
  UpdateConfiguration,
  ReadRawMaterialVersion,
} from "../../api/IndividualFunction";

function Settings() {
  const [loading, setLoading] = useState(false);
  const [Refreshloading, setRefreshLoading] = useState(false);
  const [OverHeadCost, setOverHeadCost] = useState("");
  const [Margin, setMargin] = useState("");
  const [RoundOff, setRoundOff] = useState(0);
  // const [FreightCharges, setFreightCharges] = useState(0);
  const [Password, setPassword] = useState("");
  const [Fulldata, setFulldata] = useState({});
  const [RawMaterialVerions, setRawMaterialVerions] = useState([]);
  const [RawMaterialIdSelected, setRawMaterialIdSelected] = useState(null);

  const navigate = useNavigate();

  async function fetchdata() {
    setLoading(true);
    const responseData = await ReadConfiguration();
    if (responseData["status"] === 200) {
      setOverHeadCost(responseData["data"]["OverHeadCost"]);
      setMargin(responseData["data"]["Margin"]);
      setRoundOff(responseData["data"]["RoundOff"]);
      setPassword(responseData["data"]["Password"]);
      // setFreightCharges(responseData["data"]["freightCharges"]);
      setFulldata(responseData["data"]);
      setRawMaterialIdSelected(
        responseData["data"]["SelectedRawMaterialVersion"]
      );
    }
    const RawMaterialresponseData = await ReadRawMaterialVersion();
    if (RawMaterialresponseData["status"] === 200) {
      setRawMaterialVerions(RawMaterialresponseData["data"]);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchdata();
  }, []);

  const UpdateThedata = async () => {
    setLoading(true);
    let request = {};
    if (Fulldata["OverHeadCost"] !== OverHeadCost) {
      request["OverHeadCost"] = OverHeadCost;
    }
    if (Fulldata["Margin"] !== Margin) {
      request["Margin"] = Margin;
    }
    if (Fulldata["RoundOff"] !== RoundOff) {
      request["RoundOff"] = RoundOff;
    }
    // if (Fulldata["freightCharges"] !== FreightCharges) {
    //   request["freightCharges"] = FreightCharges;
    // }
    if (Fulldata["Password"] !== Password) {
      request["Password"] = Password;
    }
    if (Object.keys(request).length === 0) {
      navigate("/zoefix/rawMaterial");
    } else {
      const responsedata = await UpdateConfiguration(request);
      // if (responsedata["status"] === 200) {
      //   navigate("/zoefix/dashboard");
      // }
    }
    setLoading(false);
  };
  const ChangedRawMaterialVersion = async () => {
    setLoading(true);
    const responsedata = await UpdateConfiguration({
      SelectedRawMaterialVersion: RawMaterialIdSelected,
    });
    setLoading(false);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Configuration</h4>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <CircleSpinner
                size={20}
                color="orange"
                loading={Refreshloading}
              />

              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>OverHead Cost (USD)</label>
                    <input
                      type="number"
                      maxLength="30"
                      value={OverHeadCost}
                      onChange={(e) => setOverHeadCost(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Margin</label>
                    <input
                      type="number"
                      maxLength="30"
                      value={Margin}
                      onChange={(e) => setMargin(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Round Off</label>
                    <input
                      type="number"
                      maxLength="30"
                      value={RoundOff}
                      onChange={(e) => {
                        e.target.value >= 0 && e.target.value < 10
                          ? setRoundOff(e.target.value)
                          : setRoundOff(0);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Frieght Charges</label>
                    <input
                      type="number"
                      // maxLength="30"
                      value={FreightCharges}
                      onChange={(e) => setFreightCharges(e.target.value)}
                    />
                  </div>
                </div> */}

                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Change Password</label>
                    <input
                      type="text"
                      maxLength="30"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <hr />
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Raw material Version</label>
                    <select
                      className="checkInput"
                      onChange={(e) => setRawMaterialIdSelected(e.target.value)}
                      value={RawMaterialIdSelected}
                    >
                      <option value="" hidden>
                        Load a Raw material option
                      </option>
                      {RawMaterialVerions.map((data, index) => (
                        <option value={data["_id"]}>
                          {data["VersionName"]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 ml-4 mt-4">
                  <button
                    className="btn btn-submit"
                    disabled={loading || !RawMaterialIdSelected}
                    onClick={ChangedRawMaterialVersion}
                  >
                    {loading ? (
                      <CircleSpinner
                        size={20}
                        color="black"
                        loading={loading}
                      />
                    ) : (
                      "Load Version"
                    )}
                  </button>
                </div>
              </div>
              <button
                className="btn btn-primary mt-4"
                disabled={loading}
                onClick={UpdateThedata}
              >
                {loading ? (
                  <CircleSpinner size={20} color="black" loading={loading} />
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
