import axios from "axios";
// import { toast } from 'react-toastify';
import {
  successMessage,
  errorMessage,
} from "../MainPage/Functions/CommonFunctions";

const ErrorResponse = { status: 500, error: "Something Went Wrong" };

const getAuthToken = () => {
  return localStorage.getItem("authToken"); // Retrieve the JWT from localStorage
};

// Function to set JWT in the header for axios requests
const getAuthHeaders = () => {
  const token = getAuthToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

axios.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    console.log(error);
    if (error.response && error.response.status === 403) {
      errorMessage("Please login again");
      window.location.href = "/signIn"; // Replace with your login page route
    }
    return Promise.reject(error); // Propagate other errors
  }
);

export const CreateFunction = async (api, data) => {
  console.log(data);
  try {
    const response = await axios.post(api, data, {
      headers: getAuthHeaders(), // Attach JWT to request headers
    });
    successMessage("Action completed successfully!");
    return { status: 200, data: response.data };
  } catch (error) {
    console.log(error);
    errorMessage("Something Went Wrong");
    return ErrorResponse;
  }
};
export const ReadFunction = async (api) => {
  try {
    const response = await axios.get(api, {
      headers: getAuthHeaders(), // Attach JWT to request headers
    });
    return { status: 200, data: response.data };
  } catch (error) {
    console.log(error);
    errorMessage("Something Went Wrong");
    return ErrorResponse;
  }
};
export const UpdateFunction = async (api, id, updatedData) => {
  try {
    const response = await axios.put(`${api}/${id}`, updatedData, {
      headers: getAuthHeaders(), // Attach JWT to request headers
    });
    successMessage("Customer Updated successfully!");
    return { status: 200, data: response.data };
  } catch (error) {
    console.log(error);
    errorMessage("Something Went Wrong");
    return ErrorResponse;
  }
};
export const DeleteFunction = async (api, id) => {
  try {
    const response = await axios.delete(`${api}/${id}`, {
      headers: getAuthHeaders(), // Attach JWT to request headers
    });
    successMessage("Deleted successfully!");
    return { status: 200, data: response.data };
  } catch (error) {
    console.log(error);
    errorMessage("Something Went Wrong");
    return ErrorResponse;
  }
};
