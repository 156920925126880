import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../../EntryFile/antd.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClassicSpinner } from "react-spinners-kit";
import { useForm } from "react-hook-form";
import {
  PlusIcon,
  Search,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import {
  ReadBrandList,
  UpdateConfiguration,
} from "../../api/IndividualFunction";
import { Modal, Form } from "react-bootstrap";

const ListBrands = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [BrandModal, setBrandModal] = useState(false);
  const navigate = useNavigate();
  const { register, getValues, setValue } = useForm();

  async function fetchdata() {
    setLoading(true);
    const responseData = await ReadBrandList();
    if (responseData["status"] === 200) {
      setData(responseData["data"]["Brands"]);
      setId(responseData["data"]["_id"]);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchdata();
  }, []);
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        animation={false}
      >
        <Modal.Title>
          <h5 className="modal-title p-4">Add New Brand</h5>
        </Modal.Title>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Brand Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                maxLength="30"
                placeholder="Enter brand name"
                {...register("brandName", { required: true })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel mr-4"
            onClick={() => setBrandModal("")}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="btn btn-submit"
            onClick={AddNewBrand}
            disabled={loading}
          >
            save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
  const updateBrand = async (brandData) => {
    const responsedata = await UpdateConfiguration({ Brands: brandData });
    if (responsedata["status"] === 200) {
      setBrandModal(false);
    }
    setData(brandData);
  };

  const AddNewBrand = () => {
    const indValue = getValues("brandName");
    setValue("brandName");
    updateBrand([...data, indValue]);
  };

  const PressedDelete = async (id) => {
    let temp = data.filter((item, index) => index !== id);
    updateBrand(temp);

    // setLoading(true);
    // const responseData = await DeleteRawMaterial(id);
    // if ((responseData["status"] = 200)) {
    //   fetchdata();
    // }
    // setLoading(false);
  };

  const columns = [
    {
      title: "SL.No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Action",
      render: (record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => PressedDelete(record.key)}
        >
          <img src={DeleteIcon} alt="img" />
        </div>
      ),
    },
  ];

  return (
    <>
      <MyVerticallyCenteredModal
        show={BrandModal}
        onHide={() => setBrandModal(false)}
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Brands</h4>
              <h6>Brands List</h6>
            </div>
            <div className="page-btn">
              <div
                className="btn btn-added"
                onClick={() => setBrandModal(true)}
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                Add New Brand
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              {/* <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="text"
                      placeholder="Search..."
                    />
                    <Link to="#" className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </Link>
                  </div>
                </div>
              </div> */}
              <div className="table-responsive">
                <Table
                  className="table datanew dataTable no-footer"
                  columns={columns}
                  dataSource={data.map((name, index) => ({
                    key: index, // This is often used as a unique identifier for each row
                    index: index + 1, // For display purposes, starting from 1
                    name: name,
                  }))}
                  pagination={false}
                  rowKey={(record) => record.User_id}
                  loading={{
                    indicator: (
                      <div>
                        <ClassicSpinner
                          size={50}
                          color="black"
                          loading={loading}
                        />{" "}
                      </div>
                    ),
                    spinning: loading,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ListBrands;
