import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../../EntryFile/antd.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClassicSpinner } from "react-spinners-kit";
import {
  PlusIcon,
  Search,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import { ReadPackingList, DeletePacking } from "../../api/IndividualFunction";

const ListPacking = () => {
  const [data, setData] = useState([]);
  const [PackingComponentType, setPackingComponentType] = useState("lubricant");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function fetchdata() {
    setLoading(true);
    const responseData = await ReadPackingList();
    console.log(responseData);
    if (responseData["status"] === 200) {
      setData(responseData["data"]);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchdata();
  }, []);

  const Pressededit = (EditData) => {
    navigate("/zoefix/packing/edit", { state: { EditData: EditData } });
  };

  const PressedDelete = async (id) => {
    setLoading(true);
    const responseData = await DeletePacking(id);
    if ((responseData["status"] = 200)) {
      fetchdata();
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "SL.No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Packing Type",
      dataIndex: "type",
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "Packing Cost",
      dataIndex: "cost",
    },
    {
      title: "Quantity Included (Litre / Kg)",
      dataIndex: "litreIncluded",
    },
    {
      title: "Quantity occupied in 20 Feet Container",
      dataIndex: "freightCharges",
    },
    {
      title: "Action",
      render: (record) => (
        <div style={{ display: "flex" }}>
          <div className="me-3" style={{ cursor: "pointer" }}>
            <img src={EditIcon} alt="img" onClick={() => Pressededit(record)} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => PressedDelete(record._id)}
          >
            <img src={DeleteIcon} alt="img" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Packing</h4>
              <h6>Packing List</h6>
            </div>
            <div className="page-btn">
              <Link to="/zoefix/packing/add" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
                Add New Packing
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-top justify-content-between align-items-center">
                {/* <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="text"
                      placeholder="Search..."
                    />
                    <Link to="#" className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </Link>
                  </div>
                </div> */}
                <div className="graph-sets">
                  Packing Type :
                  <div className="dropdown ml-4">
                    <select
                      className="form-control"
                      value={PackingComponentType}
                      onChange={(e) => setPackingComponentType(e.target.value)}
                    >
                      <option value="lubricant">Lubricants</option>
                      <option value="grease">Grease</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  className="table datanew dataTable no-footer"
                  columns={columns}
                  dataSource={data[PackingComponentType]}
                  pagination={false}
                  rowKey={(record) => record.User_id}
                  loading={{
                    indicator: (
                      <div>
                        <ClassicSpinner
                          size={50}
                          color="black"
                          loading={loading}
                        />{" "}
                      </div>
                    ),
                    spinning: loading,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ListPacking;
