import React,{useEffect} from 'react'
import {Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ListProduct from './ListProduct';
import AddNewProduct from './AddNewProduct';
import EditProduct from './EditProduct';



export const Product_Index = ()=> {


    return (
    <Routes>
        <Route path="/" element={<ListProduct/>} /> 
        <Route path="/add" element={<AddNewProduct/>} />
        <Route path="/edit" element={<EditProduct/>} /> 
    </Routes>)
}
