import React, { useState, useContext } from "react";
import {
  Logo,
  SmallLogo,
  Closes,
  HeaderSearch,
  FlagIn1,
  Notification,
  Avatar2,
  Avatar3,
  Avatar6,
  Avatar17,
  Avatar13,
  Logout,
  NoImage,
} from "../../EntryFile/imagePath";
import { Link } from "react-router-dom";
import { ApplicationAttributes } from "../App";
import { CircleSpinner } from "react-spinners-kit";

const Header = (props) => {
  const [searchBar, SetSearchBar] = useState(false);
  const [toggle, SetToggle] = useState(false);
  const { B2BName, B2BUser, HeaderLoading } = useContext(ApplicationAttributes);
  const UserImage = NoImage;
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const sidebarOverlay = () => {
    document.querySelector(".main-wrapper").classList.toggle("slide-nav");
    document.querySelector(".sidebar-overlay").classList.toggle("opened");
    document.querySelector("html").classList.toggle("menu-opened");
  };

  let pathname = window.location.pathname;

  return (
    <>
      <div className="header">
        {/* Logo */}
        <div
          className={`header-left ${toggle ? "" : "active"}`}
          onMouseLeave={expandMenu}
          onMouseOver={expandMenuOpen}
        >
          <Link to="/zoefix/dashboard" className="logo rounded-6">
            <img src={Logo} alt="" />
          </Link>
          <Link to="/zoefix/dashboard" className="logo-small rounded-6">
            <img src={Logo} alt="" />
          </Link>
          <Link
            id="toggle_btn"
            to="#"
            style={{
              display: pathname.includes("tasks")
                ? "none"
                : pathname.includes("compose")
                ? "none"
                : "",
            }}
            onClick={handlesidebar}
          ></Link>{" "}
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={sidebarOverlay}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-img">
                <img src={UserImage} alt="" />
                <span className="status online" />
              </span>
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  <span className="user-img">
                    <img src={UserImage} alt="" />
                    <span className="status online" />
                  </span>
                  <div className="profilesets">
                    <h6>Admin</h6>
                    <h5>German Mirror</h5>
                  </div>
                </div>

                <hr className="m-0" />
                <Link className="dropdown-item" to="/zoefix/pricesheet">
                  <i className="me-2" data-feather="settings" />
                  Price Sheet
                </Link>
                <Link className="dropdown-item" to="/zoefix/configuration">
                  <i className="me-2" data-feather="user" /> Configuration
                </Link>

                <hr className="m-0" />
                <Link className="dropdown-item logout pb-0" to="/signIn">
                  <img src={Logout} className="me-2" alt="img" />
                  Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="/dream-pos/dashboard">
              My Profile
            </Link>
            <Link className="dropdown-item" to="/dream-pos/settings">
              Settings
            </Link>
            <Link className="dropdown-item" to="/signIn">
              Logout
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
    </>
  );
};

export default Header;
