import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../../EntryFile/antd.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClassicSpinner } from "react-spinners-kit";
import {
  PlusIcon,
  Search,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import {
  ReadProductList,
  DeleteProduct,
  ReadBrandList,
} from "../../api/IndividualFunction";

const ListProduct = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [BrandList, setBrandList] = useState([]);
  const [ProductBrand, setProductBrand] = useState("");
  const [ProductType, setProductType] = useState("");
  const navigate = useNavigate();

  async function fetchdata() {
    setLoading(true);
    const responseData = await ReadProductList();
    if (responseData["status"] === 200) {
      console.log(responseData["data"]);
      setData(responseData["data"]);
    }
    const responseData1 = await ReadBrandList();
    if (responseData1["status"] === 200) {
      setBrandList(responseData1["data"]["Brands"]);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchdata();
  }, []);

  const Pressededit = (EditData) => {
    navigate("/zoefix/product/edit", { state: { EditData: EditData } });
  };

  const PressedDelete = async (id) => {
    setLoading(true);
    const responseData = await DeleteProduct(id);
    if ((responseData["status"] = 200)) {
      fetchdata();
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Product Id",
      dataIndex: "productId",
      sorter: (a, b) => a.productId.length - b.productId.length,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      sorter: (a, b) => a.brand.length - b.brand.length,
    },

    {
      title: "API",
      dataIndex: "productApi",
    },
    {
      title: "SAE Grade",
      dataIndex: "saeGrade",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Action",
      render: (record) => (
        <div style={{ display: "flex" }}>
          <div className="me-3" style={{ cursor: "pointer" }}>
            <img src={EditIcon} alt="img" onClick={() => Pressededit(record)} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => PressedDelete(record._id)}
          >
            <img src={DeleteIcon} alt="img" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Products</h4>
              <h6>products list</h6>
            </div>
            <div className="page-btn">
              <Link to="/zoefix/product/add" className="btn btn-added">
                <img src={PlusIcon} alt="img" className="me-1" />
                Add New Product
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input mr-8">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Link to="#" className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </Link>
                  </div>
                  Type:
                  <div className="search-input ml-2 mr-8">
                    {" "}
                    <select
                      className="form-control form-control-sm"
                      value={ProductType}
                      onChange={(e) => setProductType(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="lubricant">Lubricants</option>
                      <option value="grease">Grease</option>
                    </select>
                  </div>
                  Brand:
                  <div className="search-input ml-2">
                    {" "}
                    <select
                      className="form-control form-control-sm"
                      value={ProductBrand}
                      onChange={(e) => setProductBrand(e.target.value)}
                    >
                      <option value="">All</option>
                      {BrandList &&
                        BrandList.map((data, index) => (
                          <option value={data} key={index}>
                            {data}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  className="table datanew dataTable no-footer"
                  columns={columns}
                  dataSource={
                    data.filter(
                      (item) =>
                        item.type?.includes(ProductType) &&
                        item.brand?.includes(ProductBrand) &&
                        (item.name
                          ?.toLowerCase()
                          .includes(searchText.toLowerCase()) ||
                          item.productId
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          item.brand
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          item.productApi
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          item.saeGrade
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          item.type
                            ?.toLowerCase()
                            .includes(searchText.toLowerCase()))
                    ) || []
                  }
                  pagination={false}
                  rowKey={(record) => record.User_id}
                  loading={{
                    indicator: (
                      <div>
                        <ClassicSpinner
                          size={50}
                          color="black"
                          loading={loading}
                        />{" "}
                      </div>
                    ),
                    spinning: loading,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ListProduct;
