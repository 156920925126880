import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../../EntryFile/antd.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClassicSpinner } from "react-spinners-kit";
import {
  PlusIcon,
  Search,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import {
  ReadRawMaterialList,
  DeleteRawMaterialItem,
  CreateNewRawMaterial,
  DeleteRawMaterial
} from "../../api/IndividualFunction";
import { useForm } from "react-hook-form";
import { Modal, Form } from "react-bootstrap";

const ListRawMaterial = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register, getValues, setValue } = useForm();
  const [Version, setVersion] = useState(false);
  const [SelectedVersion, setSelectedVersion] = useState("");
  const [CloneSelectedVersion, setCloneSelectedVersion] = useState("");

  async function fetchdata() {
    setLoading(true);
    const responseData = await ReadRawMaterialList();
    if (responseData["status"] === 200) {
      if (responseData["data"]["RawMaterials"]){setData(responseData["data"]["RawMaterials"]);}
      if (responseData["data"]["Selected"]){setSelectedVersion(responseData["data"]["Selected"])}
      console.log(responseData["data"])
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchdata();
  }, []);

  const Pressededit = (EditData) => {
    navigate("/zoefix/rawMaterial/edit", { state: { EditData: EditData ,SelectedVersion: SelectedVersion } });
  };
  const PressedAdd = () => {
    navigate("/zoefix/rawMaterial/add", { state: { SelectedVersion: SelectedVersion } });
  };

  const PressedVersionDelete = async() => {
    setLoading(true);
    const responseData = await DeleteRawMaterial(SelectedVersion);
    if ((responseData["status"] = 200)) {
      fetchdata();
    }
    setLoading(false);
  }

  const PressedDelete = async (id) => {
    setLoading(true);
    const responseData = await DeleteRawMaterialItem(SelectedVersion,id);
    if ((responseData["status"] = 200)) {
      fetchdata();
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "SL.No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Action",
      render: (record) => (
        <div style={{ display: "flex" }}>
          <div className="me-3" style={{ cursor: "pointer" }}>
            <img src={EditIcon} alt="img" onClick={() => Pressededit(record)} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => PressedDelete(record._id)}
          >
            <img src={DeleteIcon} alt="img" />
          </div>
        </div>
      ),
    },
  ];
  const AddNewVersion = async () => {
    setLoading(true);
    const indValue = getValues("brandName");
    setValue("brandName");
    const responsedata = await CreateNewRawMaterial({
      VersionName: indValue,
      CloneVersionCreation : CloneSelectedVersion,
      Materials: [],
    });
    if (responsedata["status"] === 200) {
      fetchdata();
      setCloneSelectedVersion("")
      setVersion(false);
    }
    setLoading(false);
  };
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        animation={false}
      >
        <Modal.Title>
          <h5 className="modal-title p-4">Add New Version</h5>
        </Modal.Title>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Version Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                maxLength="30"
                placeholder="Enter Version name"
                {...register("brandName", { required: true })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
            <Form.Label>
                Clone from Current Version ?
            </Form.Label>
            <select
                        className="form-control"
                        value={CloneSelectedVersion}
                        onChange={(e) => setCloneSelectedVersion(e.target.value)}
                      >
                        <option value="" >Create a fresh Version</option>
                        {data && data.map((item, index) => (
                          <option key={index} value={item["_id"]}>
                            {item["VersionName"]}
                          </option>
                        ))}
                      </select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel mr-4"
            onClick={() => setVersion(false)}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="btn btn-submit"
            onClick={AddNewVersion}
            disabled={loading}
          >
            save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <MyVerticallyCenteredModal
        show={Version}
        onHide={() => setVersion(false)}
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Raw materials</h4>
              <h6>Raw Material list</h6>
            </div>
            <div className="page-btn"></div>
            <div className="page-btn" style={{ display: "flex" }}>
              <div
                className="btn btn-added  mr-4"
                onClick={() => setVersion(true)}
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                New Version
              </div>
              {data && data.length > 0 && SelectedVersion != "" && (
                <div onClick={PressedAdd} className="btn btn-added">
                  <img src={PlusIcon} alt="img" className="me-1" />
                  New Raw Material
                </div>
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-top justify-content-between align-items-center">
                {/* <div className="search-set ">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="text"
                      placeholder="Search..."
                    />
                    <Link to="#" className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </Link>
                  </div>
                </div> */}
                {data && (
                  <div className="graph-sets"> Version : 
                    <div className="dropdown ml-4">
                      <select
                        className="form-control"
                        value={SelectedVersion}
                        onChange={(e) => setSelectedVersion(e.target.value)}
                      >
                        <option value="" disabled>Select A Version</option>
                        {data.map((item, index) => (
                          <option key={index} value={item["_id"]}>
                            {item["VersionName"]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <div>
                {data && (<div onClick={PressedVersionDelete} className="btn btn-white">
                  <img src={DeleteIcon} alt="img" className="me-1" />
                  Delete This Version
                </div>)}
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  className="table datanew dataTable no-footer"
                  columns={columns}
                  dataSource={
                    data
                      ? data.find((item) => item._id == SelectedVersion)
                          ?.Materials
                      : []
                  }
                  pagination={false}
                  loading={{
                    indicator: (
                      <div>
                        <ClassicSpinner
                          size={50}
                          color="black"
                          loading={loading}
                        />{" "}
                      </div>
                    ),
                    spinning: loading,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ListRawMaterial;
