import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ListUsers from "./ListUsers";
// import AddNewRawMaterial from './AddNewRawMaterial';
// import EditRawMaterial from './EditRawMaterial';

export const Users_Index = () => {
  return (
    <Routes>
      <Route path="/" element={<ListUsers />} />
      {/* <Route path="/add" element={<AddNewRawMaterial/>} />
        <Route path="/edit" element={<EditRawMaterial/>} />  */}
    </Routes>
  );
};
