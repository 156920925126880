import React,{useEffect} from 'react'
import {Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ListBrands from './ListBrands';

export const Brands_Index = ()=> {

    return (
    <Routes>
        <Route path="/" element={<ListBrands/>} /> 
    </Routes>)
}
