import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Dashboard,
  Expense,
  People,
  Places,
  Product,
  Time,
  Users1,
  settings,
  Purchase,
  Quotation,
  Return,
  Transfer,
  Sales1,
} from "../../EntryFile/imagePath";
import { Scrollbars } from "react-custom-scrollbars-2";
import FeatherIcon from "feather-icons-react";
import { ApplicationAttributes } from "../App";
import { WorkFlowSpecificNameChanger } from "../../MainPage/Functions/CommonFunctions";

const Sidebar = (props) => {
  const [isSideMenu, setSideMenu] = useState("");
  const navigate = useNavigate();

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };

  let pathname = window.location.pathname;
  useEffect(() => {
    document.querySelector(".main-wrapper").classList.remove("slide-nav");
    document.querySelector(".sidebar-overlay").classList.remove("opened");
    document.querySelector(".sidebar-overlay").onclick = function () {
      this.classList.remove("opened");
      document.querySelector(".main-wrapper").classList.remove("slide-nav");
    };
  }, [pathname]);
  return (
    <div className="sidebar" id="sidebar">
      <Scrollbars>
        <div className="sidebar-inner slimscroll">
          <div
            id="sidebar-menu"
            className="sidebar-menu"
            onMouseLeave={expandMenu}
            onMouseOver={expandMenuOpen}
          >
            {/* <img src="https://germanmirror.com/wp-content/uploads/2022/11/Mirr-Oil-2.png" /> */}
            <ul>
              <li className={pathname.includes("dashboard") ? "active" : ""}>
                <Link
                  to="/zoefix/dashboard"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={Dashboard} alt="img" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className={pathname.includes("rawMaterial") ? "active" : ""}>
                <Link
                  to="/zoefix/rawMaterial"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={Sales1} alt="img" />
                  <span>Raw materials</span>
                </Link>
              </li>
              <li className={pathname.includes("packing") ? "active" : ""}>
                <Link
                  to="/zoefix/packing"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={Purchase} alt="img" />
                  <span>Packing</span>
                </Link>
              </li>
              <li className={pathname.includes("brands") ? "active" : ""}>
                <Link
                  to="/zoefix/brands"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={Quotation} alt="img" />
                  <span>Brands</span>
                </Link>
              </li>
              <li className={pathname.includes("product") ? "active" : ""}>
                <Link
                  to="/zoefix/product"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={Product} alt="img" />
                  <span>Product</span>
                </Link>
              </li>
              <li className={pathname.includes("users") ? "active" : ""}>
                <Link
                  to="/zoefix/users"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={People} alt="img" />
                  <span>Users</span>
                </Link>
              </li>
              <li className={pathname.includes("pricesheet") ? "active" : ""}>
                <Link
                  to="/zoefix/pricesheet"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={Expense} alt="img" />
                  <span>Price Sheet</span>
                </Link>
              </li>

              <li
                className={pathname.includes("configuration") ? "active" : ""}
              >
                <Link
                  to="/zoefix/configuration"
                  onClick={() => toggleSidebar(isSideMenu == "" ? "" : "")}
                >
                  <img src={settings} alt="img" />
                  <span>Configuration</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default Sidebar;
