let ApiHead = process.env.REACT_APP_BACKEND_URL

export const GenerateApiUrl = (ApiUrl) => {return `${ApiHead}/${ApiUrl}`}


export const RawMaterial = GenerateApiUrl("raw-material")
export const Packing = GenerateApiUrl("packing")
export const Product = GenerateApiUrl("product")
export const PriceSheet = GenerateApiUrl("price-list")
export const PriceSheetPrime = GenerateApiUrl("price-list/prime")
export const PriceSheetLatest = GenerateApiUrl("price-list/latest")
export const Configuration = GenerateApiUrl("configuration")
export const ReportDownloadApi = GenerateApiUrl("files/report.xlsx")