import React, { useState, useContext } from "react";
import {
  LoginImage,
  Logo,
  MailIcon,
  GoogleIcon,
  FacebookIcon,
  loginCustom,
} from "../EntryFile/imagePath";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SphereSpinner, ClassicSpinner } from "react-spinners-kit";
import { ApplicationAttributes } from "./App";
import { HandleUserSignIn } from "../api/IndividualFunction";

const SignInPage = (props) => {
  const [eye, seteye] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sentotp, setSentOtp] = useState(false);
  const [usedGoogle, setUsedGoogle] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { setB2BName, setAgentCode } = useContext(ApplicationAttributes);
  const navigate = useNavigate();

  const ChangeEye = () => {
    seteye(!eye);
  };
  const OnSignIn = async (params) => {
    setLoading(true);
    const response = await HandleUserSignIn({
      username: email.trim(),
      password: password,
    });
    if (response["status"] === 200) {
      localStorage.setItem("authToken", response["data"]["token"]);
      if (response["data"]["authenticatedAsAdmin"]) {
        navigate("/zoefix/dashboard");
      } else {
        navigate("/user");
      }
    }
    setLoading(false);
    setUsedGoogle(false);
  };

  return (
    <>
      <div className="main-wrapper">
        <Helmet>
          <title>GM Costing - SignIn</title>
          <meta name="description" content="SignIn page" />
        </Helmet>
        <div className="account-content">
          <div className="login-wrapper">
            <div className="login-content">
              <div className="login-userset">
                {usedGoogle ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ClassicSpinner
                      size={30}
                      color="black"
                      loading={usedGoogle}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="login-logo">
                      <img
                        src={
                          "https://germanmirror.com/wp-content/uploads/2022/11/Mirr-Oil-2.png"
                        }
                        alt="img"
                      />
                    </div>
                    <div className="login-userheading">
                      <h3>Sign In </h3>
                      <h4>
                        {sentotp
                          ? "An activation code has been dispatched"
                          : "Please login to your account"}
                      </h4>
                    </div>
                    <div className="form-login">
                      <label>Email</label>
                      <div className="form-addons">
                        <input
                          type="text"
                          maxLength="30"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter your email address"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <label>Password</label>
                      <div className="form-addons">
                        <input
                          type={eye ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter your password"
                          required
                        />
                        <div className="iconify-position-signup">
                          <iconify-icon
                            icon={
                              eye ? "ic:round-remove-red-eye" : "mdi:eye-off"
                            }
                            style={{ color: "grey" }}
                            onClick={ChangeEye}
                          ></iconify-icon>
                        </div>
                      </div>
                    </div>

                    <div className="form-login">
                      <button
                        className="btn btn-login"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        disabled={loading}
                        onClick={OnSignIn}
                      >
                        {loading ? (
                          <SphereSpinner
                            size={15}
                            color="black"
                            loading={loading}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="login-img">
              <img src={loginCustom} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInPage;
