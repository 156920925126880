import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import {
  ReadRawMaterialSelected,
  ReadPackingList,
  CreateNewProduct,
  ReadBrandList,
} from "../../api/IndividualFunction";
import { Table } from "antd";
import { DeleteIcon } from "../../EntryFile/imagePath";
import { errorMessage } from "../Functions/CommonFunctions";

const AddNewProduct = (props) => {
  let RoundOff = 3;
  const [loading, setLoading] = useState(false);
  const [ProductBrand, setProductBrand] = useState("");
  const [ProductType, setProductType] = useState("lubricant");
  const [ProductDensity, setProductDensity] = useState(0);
  const [ProductName, setProductName] = useState("");
  const [ProductApi, setProductApi] = useState("");
  const [ProductSaeGrade, setProductSaeGrade] = useState("");
  const [RawMaterialSelected, setRawMaterialSelected] = useState("");
  const [RawMaterialSelectedComposition, setRawMaterialSelectedComposition] =
    useState("");
  const [RawMaterialList, setRawMaterialList] = useState([]);
  const [RawMaterialComposition, setRawMaterialComposition] = useState([]);
  const [AvailablePacking, setAvailablePacking] = useState([]);
  const [AvailablePackingList, setAvailablePackingList] = useState([]);
  const [BrandList, setBrandList] = useState([]);

  const navigate = useNavigate();

  const onSubmit = async () => {
    setLoading(true);
    const responsedata = await CreateNewProduct({
      //   productId: ProductId,
      density: ProductDensity,
      brand: ProductBrand,
      type: ProductType,
      name: ProductName,
      productApi: ProductApi,
      saeGrade: ProductSaeGrade,
      availablePacking: AvailablePacking,
      rawMaterialComposition: RawMaterialComposition.map(
        ({ id, composition }) => ({ rawMaterialId: id, composition })
      ),
    });
    // console.log({
    //   //   productId: ProductId,
    //   density: ProductDensity,
    //   brand: ProductBrand,
    //   type: ProductType,
    //   name: ProductName,
    //   productApi: ProductApi,
    //   saeGrade: ProductSaeGrade,
    //   availablePacking: AvailablePacking,
    //   rawMaterialComposition: RawMaterialComposition.map(
    //     ({ id, composition }) => ({ rawMaterialId: id, composition })
    //   ),
    // });
    if (responsedata["status"] === 200) {
      navigate("/zoefix/product");
    }
    setLoading(false);
  };

  async function fetchdata() {
    setLoading(true);
    const responseData1 = await ReadRawMaterialSelected();
    if (responseData1["status"] === 200) {
      setRawMaterialList(responseData1["data"]["Materials"]);
    }
    const responseData2 = await ReadPackingList();
    if (responseData2["status"] === 200) {
      setAvailablePackingList(responseData2["data"]);
    }
    const responseData3 = await ReadBrandList();
    if (responseData3["status"] === 200) {
      setBrandList(responseData3["data"]["Brands"]);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchdata();
  }, []);

  const AddItemToCart = () => {
    if (!RawMaterialSelectedComposition > 0) {
      return;
    }
    const CheckExistOnList = RawMaterialComposition.find(
      (item) => item.id === RawMaterialSelected
    );
    const TotalComposition = RawMaterialComposition.reduce(
      (acc, item) => acc + item.composition,
      0
    );

    const roundedTotalComposition = parseFloat(
      TotalComposition.toFixed(RoundOff)
    );

    if (
      !CheckExistOnList &&
      roundedTotalComposition + parseFloat(RawMaterialSelectedComposition) <=
        100
    ) {
      // Check if CheckExistOnList is undefined
      let singlerawMaterial = RawMaterialList.find(
        (item) => item._id === RawMaterialSelected
      );

      setRawMaterialComposition([
        ...RawMaterialComposition,
        {
          name: singlerawMaterial["name"],
          composition: parseFloat(RawMaterialSelectedComposition),
          id: RawMaterialSelected,
        },
      ]);

      setRawMaterialSelectedComposition("");
      setRawMaterialSelected("");
    }
  };
  const RemoveFromCart = (id) => {
    setRawMaterialComposition(
      RawMaterialComposition.filter((item) => item.id !== id)
    );
  };
  const TotalComposition = RawMaterialComposition.reduce(
    (acc, item) => acc + item.composition,
    0
  );
  const roundedTotalComposition = parseFloat(
    TotalComposition.toFixed(RoundOff)
  );

  const onCancel = async () => {
    navigate("/zoefix/product");
  };
  const handleSelectChange = (SelectedId) => {
    if (AvailablePacking.includes(SelectedId)) {
      setAvailablePacking(
        AvailablePacking.filter((item) => item !== SelectedId)
      );
    } else {
      setAvailablePacking([...AvailablePacking, SelectedId]);
    }
  };
  const handleAllSelectChange = () => {
    if (AvailablePackingList[ProductType].length === AvailablePacking.length) {
      setAvailablePacking([]);
    } else {
      setAvailablePacking(
        AvailablePackingList[ProductType].map((item) => item._id)
      );
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Add your New Product</h4>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                {/* <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Product Id <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      value={ProductId}
                      onChange={(e) => setProductId(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Product Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="60"
                      value={ProductName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Product Brand<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      value={ProductBrand}
                      className="checkInput"
                      onChange={(e) => setProductBrand(e.target.value)}
                    >
                      <option value="" disabled>
                        Select a brand
                      </option>
                      {BrandList &&
                        BrandList.map((data, index) => (
                          <option value={data} key={index}>
                            {data}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Product Type<span style={{ color: "red" }}>*</span>
                    </label>

                    <select
                      value={ProductType}
                      className="checkInput"
                      onChange={(e) => setProductType(e.target.value)}
                    >
                      <option value="lubricant">Lubricants</option>
                      <option value="grease">Grease</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Product Density<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      maxLength="30"
                      value={ProductDensity}
                      onChange={(e) => setProductDensity(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Product API<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      value={ProductApi}
                      onChange={(e) => setProductApi(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Product SAE Grade<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      value={ProductSaeGrade}
                      onChange={(e) => setProductSaeGrade(e.target.value)}
                    />
                  </div>
                </div>

                {AvailablePackingList && AvailablePackingList[ProductType] && (
                  <div
                    className="col-lg-12 ml-4 mr-4 mb-4"
                    style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        AvailablePackingList[ProductType].length ===
                        AvailablePacking.length
                      }
                      onChange={handleAllSelectChange}
                    />
                    Select All
                    {AvailablePackingList[ProductType].map((data, index) => (
                      <div
                        style={{ display: "flex" }}
                        className=" mr-4"
                        id={data["_id"]}
                      >
                        <input
                          type="checkbox"
                          checked={AvailablePacking.includes(data["_id"])}
                          onChange={() => handleSelectChange(data["_id"])}
                        />
                        <div className="ml-2 "> {data["type"]}</div>
                      </div>
                    ))}
                  </div>
                )}
                <hr />
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Raw Material</label>
                    <select
                      value={RawMaterialSelected}
                      className="checkInput"
                      onChange={(e) => setRawMaterialSelected(e.target.value)}
                    >
                      <option value="">Select a Raw Material</option>
                      {RawMaterialList &&
                        RawMaterialList.map((data, index) => (
                          <option value={data["_id"]} key={index}>
                            {data["name"]}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {RawMaterialSelected != "" && (
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Raw Material Composition</label>
                      <input
                        type="number"
                        maxLength="30"
                        value={RawMaterialSelectedComposition}
                        onChange={(e) =>
                          setRawMaterialSelectedComposition(e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
                {RawMaterialSelected != "" &&
                  RawMaterialSelectedComposition != "" && (
                    <div className="col-lg-3 col-sm-6 col-12 mt-4">
                      <div
                        className="btn btn-primary mt-1"
                        onClick={AddItemToCart}
                      >
                        Add Raw Material
                      </div>
                    </div>
                  )}

                <div className="table-responsive col-lg-12">
                  <Table
                    className="table datanew dataTable no-footer"
                    columns={[
                      { title: "Raw Material", dataIndex: "name" },
                      {
                        title: "Composition",
                        dataIndex: "composition",
                        align: "right",
                      },
                      {
                        align: "right",
                        render: (record) => (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => RemoveFromCart(record.id)}
                          >
                            <img src={DeleteIcon} alt="img" />
                          </div>
                        ),
                      },
                    ]}
                    dataSource={RawMaterialComposition}
                    pagination={false}
                  />
                </div>
                <div className="mt-4">
                  <p className="ml-4 mb-0 mr-4 billFontColour">
                    Total Composition Added : {roundedTotalComposition} %
                  </p>
                </div>

                <div className="col-lg-12 mt-4">
                  <button
                    className="btn btn-cancel mr-4"
                    onClick={onCancel}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-submit"
                    onClick={() =>
                      roundedTotalComposition == 100
                        ? onSubmit()
                        : errorMessage(
                            `composition is ${roundedTotalComposition}% only, Please make it 100`
                          )
                    }
                    disabled={loading}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircleSpinner
                          size={20}
                          color="black"
                          loading={loading}
                        />{" "}
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </>
  );
};
export default AddNewProduct;
