import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../../EntryFile/antd.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClassicSpinner } from "react-spinners-kit";
import {
  PlusIcon,
  Search,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
import {
  ReadUsersList,
  UpdateConfiguration,
} from "../../api/IndividualFunction";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ListUsers = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [BrandModal, setBrandModal] = useState(false);
  const [PassModal, setPassModal] = useState(false);
  const navigate = useNavigate();
  const { register, getValues, setValue } = useForm();

  async function fetchdata() {
    setLoading(true);
    const responseData = await ReadUsersList();
    if (responseData["status"] === 200) {
      // console.log(responseData["data"]["Users"])
      setData(responseData["data"]["Users"]);
      setId(responseData["data"]["_id"]);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchdata();
  }, []);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        animation={false}
      >
        <Modal.Title>
          <h5 class="modal-title p-4">Add New User</h5>
        </Modal.Title>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                User Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                maxLength="30"
                placeholder="Enter brand name"
                {...register("userEmail", { required: true })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel mr-4"
            onClick={() => setBrandModal(false)}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="btn btn-submit"
            onClick={AddNewBrand}
            disabled={loading}
          >
            save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
  function ChangePasswordModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        animation={false}
      >
        <Modal.Title>
          <h5 class="modal-title p-4">Change Password</h5>
        </Modal.Title>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                User Password <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                maxLength="30"
                placeholder="Enter brand name"
                {...register("userPassword", { required: true })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel mr-4"
            onClick={() => setPassModal(false)}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="btn btn-submit"
            onClick={EditBrand}
            disabled={loading}
          >
            save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
  const updateBrand = async (brandData) => {
    const responsedata = await UpdateConfiguration({ Users: brandData });
    if (responsedata["status"] === 200) {
      // console.log(responsedata)
      fetchdata();
      setBrandModal(false);
      setPassModal(false);
    }
    setData(brandData);
  };
  // const updatePassword = async (UpdatedPassword) => {
  //   const responsedata = await UpdateConfiguration({ "Password": UpdatedPassword });
  //   if (responsedata["status"] === 200) {
  //     // console.log(responsedata)
  //     setBrandModal(false);
  //   }
  //   // setData(brandData);
  // }
  const AddNewBrand = () => {
    const indValue = getValues("userEmail");
    setValue("userEmail");
    // console.log(indValue)
    updateBrand([...data, { Username: indValue, Password: "gmclient" }]);
  };
  const EditBrand = () => {
    const indValue = getValues("userPassword");
    let temp1 = data.filter((item, index) => item._id !== getValues("EditId"));
    let temp2 = data.find((item, index) => item._id === getValues("EditId"));
    if (temp2) {
      temp2["Password"] = indValue;
      setValue("userPassword");
      updateBrand([...temp1, temp2]);
    }
  };
  const PressedDelete = async (id) => {
    let temp = data.filter((item, index) => item._id !== id);
    updateBrand(temp);
  };
  const PressedEdit = async (id) => {
    setPassModal(true);
    let temp = data.find((item, index) => item._id === id);
    if (temp) {
      setValue("userPassword", temp["Password"]);
      setValue("EditId", id);
    }
  };
  const columns = [
    {
      title: "SL.No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Email",
      dataIndex: "Username",
      sorter: (a, b) => a.Username.length - b.Username.length,
    },
    {
      title: "Password",
      dataIndex: "Password",
      sorter: (a, b) => a.Username.length - b.Username.length,
    },
    {
      title: "Action",
      render: (record) => (
        <div style={{ display: "flex" }}>
          <div
            className="me-3"
            style={{ cursor: "pointer" }}
            onClick={() => PressedEdit(record._id)}
          >
            <img src={EditIcon} alt="img" />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => PressedDelete(record._id)}
          >
            <img src={DeleteIcon} alt="img" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <MyVerticallyCenteredModal
        show={BrandModal}
        onHide={() => setBrandModal(false)}
      />
      <ChangePasswordModal
        show={PassModal}
        onHide={() => setPassModal(false)}
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Users</h4>
              <h6>Users List</h6>
            </div>
            <div className="page-btn">
              <div
                className="btn btn-added"
                onClick={() => setBrandModal(true)}
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                Add New User
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              {/* <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      className="form-control form-control-sm search-icon"
                      type="text"
                      placeholder="Search..."
                    />
                    <Link to="#" className="btn btn-searchset">
                      <img src={Search} alt="img" />
                    </Link>
                  </div>
                </div>
              </div> */}
              <div className="table-responsive">
                <Table
                  className="table datanew dataTable no-footer"
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  rowKey={(record) => record.User_id}
                  loading={{
                    indicator: (
                      <div>
                        <ClassicSpinner
                          size={50}
                          color="black"
                          loading={loading}
                        />{" "}
                      </div>
                    ),
                    spinning: loading,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ListUsers;
