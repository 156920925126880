import React,{useEffect} from 'react'
import {Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ListPriceSheet from './ListPriceSheet';

export const PriceSheet_Index = ()=> {
    return (
    <Routes>
        <Route path="/" element={<ListPriceSheet/>} /> 
    </Routes>)
}
