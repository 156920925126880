import React,{useEffect} from 'react'
import {Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ListRawMaterial from './ListRawMaterial';
import AddNewRawMaterial from './AddNewRawMaterial';
import EditRawMaterial from './EditRawMaterial';




export const RawMaterial_Index = ()=> {


    return (
    <Routes>
        <Route path="/" element={<ListRawMaterial/>} /> 
        <Route path="/add" element={<AddNewRawMaterial/>} />
        <Route path="/edit" element={<EditRawMaterial/>} /> 
    </Routes>)
}
