import React, { useState,useEffect } from 'react'
import { useNavigate,useLocation } from 'react-router-dom';
import { CircleSpinner } from 'react-spinners-kit';
import { AddnewRawMaterialItem } from '../../api/IndividualFunction';

const AddNewRawMaterial = (props) => {
    const [loading, setLoading] = useState(false);
    const [RawMaterialName,setRawMaterialName] = useState("");
    const [RawMaterialPrice,setRawMaterialPrice] = useState("");
    const navigate = useNavigate()
    const loc = useLocation()
    const { SelectedVersion } = loc.state ? loc.state : {}
    console.log(SelectedVersion)
    
    useEffect(() => {
        
    }, [])

    const onSubmit = async() => {
        setLoading(true)
        const responsedata = await AddnewRawMaterialItem(SelectedVersion,{"Materials" : { "name" : RawMaterialName,"price" : RawMaterialPrice}} )
        if (responsedata["status"] === 200){navigate("/zoefix/rawMaterial")}
        setLoading(false)
    }

    const onCancel = async() => {
        navigate("/zoefix/rawMaterial")
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>Add your New Raw Material</h4>
                        </div>
                    </div>
                    {/* /add */}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Raw Material Name <span style={{color: "red"}}>*</span></label>
                                        <input 
                                            type="text" 
                                            maxLength="30"
                                            value={RawMaterialName}
                                            onChange={(e) => setRawMaterialName(e.target.value) }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Raw Material Price (USD) <span style={{color: "red"}}>*</span></label>
                                        <input 
                                            type="text" 
                                            maxLength="50"
                                            value={RawMaterialPrice}
                                            onChange={(e) => setRawMaterialPrice(e.target.value) }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                <button className="btn btn-cancel mr-4" onClick={onCancel} disabled={loading}>
                                         Cancel
                                    </button>
                                    <button className="btn btn-submit" onClick={onSubmit} disabled={loading}>
                                        {loading ?<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircleSpinner size={20} color ="black" loading={loading}/> </div> : "Submit"}
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /add */}
                </div>
            </div>
        </>
    )
}
export default AddNewRawMaterial;