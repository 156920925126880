import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import { CreateNewPacking } from "../../api/IndividualFunction";

const AddNewPacking = (props) => {
  const [loading, setLoading] = useState(false);
  const [PackingComponent, setPackingComponent] = useState("lubricant");
  const [PackingType, setPackingType] = useState("");
  const [PackingCost, setPackingCost] = useState("");
  const [PackingLitreIncluded, setPackingLitreIncluded] = useState("");
  const [FreightCharges, setFreightCharges] = useState("");
  const navigate = useNavigate();

  const onSubmit = async () => {
    setLoading(true);
    const responsedata = await CreateNewPacking({
      component: PackingComponent,
      type: PackingType,
      cost: PackingCost,
      litreIncluded: PackingLitreIncluded,
      freightCharges: FreightCharges,
    });
    if (responsedata["status"] === 200) {
      navigate("/zoefix/packing");
    }
    setLoading(false);
  };
  const onCancel = async () => {
    navigate("/zoefix/packing");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Add your New Packing Type</h4>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Packing Component <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="checkInput"
                      value={PackingComponent}
                      onChange={(e) => setPackingComponent(e.target.value)}
                    >
                      <option value="lubricant">Lubricants</option>
                      <option value="grease">Grease</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Packing Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      value={PackingType}
                      onChange={(e) => setPackingType(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      packing Cost in USD{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="50"
                      value={PackingCost}
                      onChange={(e) => setPackingCost(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Packing Litre \ Kg <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      value={PackingLitreIncluded}
                      onChange={(e) => setPackingLitreIncluded(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>
                      Quantity occupied in 20 feet container{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="50"
                      value={FreightCharges}
                      onChange={(e) => setFreightCharges(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <button
                    className="btn btn-cancel mr-4"
                    onClick={onCancel}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-submit"
                    onClick={onSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircleSpinner
                          size={20}
                          color="black"
                          loading={loading}
                        />{" "}
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </>
  );
};
export default AddNewPacking;
