import React, { useState, useEffect, useContext } from "react";
import { CircleSpinner } from "react-spinners-kit";
import { GenerateApiUrl } from "../../api/Api";
import {
  ReadPriceSheet,
  CreateNewPriceSheet,
  UpdateConfiguration,
  DownloadPriceList,
} from "../../api/IndividualFunction";

import { ReportDownloadApi } from "../../api/Api";

function Settings() {
  const [loading, setLoading] = useState(false);
  const [Refreshloading, setRefreshLoading] = useState(false);
  const [data, setData] = useState([]);
  const [SelectedData, setSelectedData] = useState("");
  const [PriceSheetVersionName, setPriceSheetVersionName] = useState("");
  const [DiscountPercentage, setDiscountPercentage] = useState(0);
  const [UserPriceSheetSelected, setUserPriceSheetSelected] = useState("");

  async function fetchdata() {
    setRefreshLoading(true);
    const responseData = await ReadPriceSheet();
    if (responseData["status"] === 200 && responseData["data"]) {
      setUserPriceSheetSelected(responseData["data"]["Selected"]);
      setData(responseData["data"]["Total"]);
    }

    setRefreshLoading(false);
  }
  useEffect(() => {
    fetchdata();
  }, []);

  const DownloadTheSelectedFile = async () => {
    const responseData = await DownloadPriceList({
      downloadId: SelectedData,
      DiscountPercentage: DiscountPercentage,
    });
    console.log(responseData);
    if (responseData["status"] == 200) {
      const link = document.createElement("a");
      link.href = ReportDownloadApi;
      link.download = "report.xlsx"; // Specify the file name

      // Append the link to the body and click it to trigger download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
  };

  const CreateNewPriceSheetsOnClicked = async () => {
    setLoading(true);
    const responsedata = await CreateNewPriceSheet({
      PriceSheetVersionName: PriceSheetVersionName,
    });
    if (responsedata["status"] === 200) {
      setPriceSheetVersionName("");
      fetchdata();
    }
    setLoading(false);
  };
  const ChangedRawMaterialVersion = async () => {
    setLoading(true);
    const responsedata = await UpdateConfiguration({
      UserSelectedPriceListVersions: UserPriceSheetSelected,
    });
    setLoading(false);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Price Sheet</h4>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <CircleSpinner
                size={20}
                color="orange"
                loading={Refreshloading}
              />
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Version Name</label>
                    <input
                      type="text"
                      maxLength="50"
                      value={PriceSheetVersionName}
                      onChange={(e) => setPriceSheetVersionName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 ml-4 mt-4">
                  <button
                    className="btn btn-submit"
                    disabled={loading}
                    onClick={CreateNewPriceSheetsOnClicked}
                  >
                    {loading ? (
                      <CircleSpinner
                        size={20}
                        color="black"
                        loading={loading}
                      />
                    ) : (
                      "Generate New Price Sheet"
                    )}
                  </button>
                </div>

                <hr />
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Download Specific Version</label>
                    <select
                      className="checkInput"
                      onChange={(e) => setSelectedData(e.target.value)}
                      value={SelectedData}
                    >
                      <option value="" disabled>
                        Select a version to download
                      </option>
                      {data.map((data, index) => (
                        <option value={data["_id"]}>
                          {data["PriceSheetVersionName"]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Discount Percentage</label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={DiscountPercentage}
                      onChange={(e) => setDiscountPercentage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 ml-4 mt-4">
                  <button
                    className="btn btn-submit"
                    disabled={loading || SelectedData == ""}
                    onClick={() =>
                      SelectedData != "" ? DownloadTheSelectedFile() : {}
                    }
                  >
                    {/*onClick={handle}*/}
                    {loading ? (
                      <CircleSpinner
                        size={20}
                        color="black"
                        loading={loading}
                      />
                    ) : (
                      "Download"
                    )}
                  </button>
                </div>
                <hr />
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group ">
                    <label>Price List For Users</label>
                    <select
                      className="checkInput"
                      onChange={(e) =>
                        setUserPriceSheetSelected(e.target.value)
                      }
                      value={UserPriceSheetSelected}
                    >
                      <option value="" disabled>
                        Load a version for users
                      </option>
                      {data.map((data, index) => (
                        <option value={data["_id"]}>
                          {data["PriceSheetVersionName"]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 ml-4 mt-4">
                  <button
                    className="btn btn-submit"
                    disabled={loading || Refreshloading}
                    onClick={ChangedRawMaterialVersion}
                  >
                    {loading ? (
                      <CircleSpinner
                        size={20}
                        color="black"
                        loading={loading}
                      />
                    ) : (
                      "Load Version"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
