import alertify from "alertifyjs";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";

export const successMessage = (message) => {
  alertify.set("notifier", "position", "top-right");
  alertify.success(message);
}
export const errorMessage = (message) => {
  alertify.set("notifier", "position", "top-right");
  alertify.error(message);
}
