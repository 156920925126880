import Dashboard from "../MainPage/Dashboard";
// import Userdashboard from "../MainPage/Userdashboard";

import {Settings_Index} from "../MainPage/Settings";
import { RawMaterial_Index } from "../MainPage/RawMaterial";
import { Product_Index } from "../MainPage/Product";
import { Packing_Index } from "../MainPage/Packing";
import { PriceSheet_Index } from "../MainPage/PriceSheet";
import { Brands_Index } from "../MainPage/Brands";
import { Users_Index } from "../MainPage/Users";
// import { User_settings } from "../MainPage/UserSettings";

export const routerService = [
    {
        path: 'dashboard',
        component: Dashboard
    },
    {
        path:'configuration',
        component: Settings_Index
    },
    {
        path:'packing',
        component: Packing_Index
    },
    {
        path:'product',
        component: Product_Index
    },
    {
        path:'rawMaterial',
        component: RawMaterial_Index
    },
    {
        path:'pricesheet',
        component: PriceSheet_Index
    },
    {
        path:'brands',
        component: Brands_Index
    },
    {
        path:'users',
        component: Users_Index
    }
]

// export const UserRouterService = [
//     {
//         path: 'dashboard',
//         component: Userdashboard
//     },
//     {
//         path: 'settings',
//         component: User_settings
//     }
// ]
